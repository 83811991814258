import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
         

         <path d="M1440 2230 c-45 -45 -36 -114 18 -142 91 -45 178 67 107 137 -31 32
-96 34 -125 5z m102 -27 c42 -37 4 -112 -50 -98 -71 18 -67 115 5 115 15 0 35
-7 45 -17z"/>
<path d="M1495 2052 c-56 -5 -75 -12 -109 -42 -31 -28 -36 -29 -67 -17 -24 8
-114 12 -304 12 -302 0 -347 -7 -418 -66 -31 -26 -77 -93 -77 -114 0 -16 20 0
48 40 45 63 123 99 235 107 l48 3 0 -405 c0 -402 0 -405 -22 -422 -12 -10 -32
-18 -45 -18 -12 0 -26 -5 -30 -11 -9 -15 544 -8 569 7 14 8 32 6 75 -9 98 -33
218 -13 292 49 117 99 120 266 8 389 -39 43 -50 63 -63 120 -36 158 -146 274
-268 284 -5 1 -6 5 -2 11 3 5 12 7 20 4 7 -3 31 6 52 20 82 56 203 19 220 -66
10 -53 19 -28 15 45 -2 39 -5 73 -6 73 -3 4 -144 9 -171 6z m-585 -492 c0
-419 0 -420 -21 -420 -15 0 -19 5 -15 16 3 9 6 198 6 420 0 350 2 404 15 404
13 0 15 -56 15 -420z m393 399 c33 -9 36 -12 26 -30 -16 -30 -13 -123 6 -158
9 -17 62 -68 118 -112 56 -45 104 -83 106 -85 10 -9 -22 -174 -43 -220 -45
-98 -145 -186 -186 -164 -24 13 -66 84 -75 127 -9 45 11 135 40 177 15 22 20
36 12 36 -31 0 -77 -107 -78 -183 -2 -88 45 -175 95 -178 12 -1 3 -5 -19 -9
-22 -5 -114 -9 -205 -10 l-165 -2 -3 404 c-1 221 -1 407 2 413 4 13 317 8 369
-6z m128 -77 c35 -37 75 -91 88 -120 14 -28 28 -52 33 -52 11 0 10 5 -7 47 -8
19 -15 44 -15 55 0 10 -4 16 -10 13 -5 -3 -10 -2 -10 3 0 54 86 -85 103 -166
6 -31 17 -61 24 -65 7 -4 13 -27 13 -51 0 -23 10 -62 22 -87 57 -117 22 -235
-86 -291 -32 -17 -61 -22 -134 -24 -53 -1 -90 2 -86 7 3 5 14 9 25 9 11 0 17
4 14 10 -3 6 1 7 9 4 39 -15 152 92 193 182 28 61 43 142 25 130 -6 -3 -18
-30 -28 -58 -20 -60 -71 -144 -105 -173 -24 -20 -24 -20 -7 0 9 11 18 25 20
30 2 6 6 9 11 7 4 -1 7 8 7 21 0 13 4 27 9 33 14 14 39 122 41 174 2 63 1 72
-9 65 -10 -6 -16 9 -26 67 -5 31 -18 47 -74 93 -73 59 -91 87 -91 141 0 22 -6
37 -15 40 -16 7 -21 24 -6 24 5 0 37 -30 72 -68z m-67 -55 c8 -7 17 -22 21
-32 3 -11 25 -36 48 -55 22 -19 37 -28 33 -20 -5 8 4 2 19 -15 14 -16 23 -23
19 -15 -6 13 -5 13 9 0 9 -8 19 -28 23 -45 7 -27 6 -29 -9 -16 -10 7 -17 20
-17 27 0 8 -5 14 -11 14 -5 0 -7 -4 -4 -10 3 -5 1 -10 -4 -10 -6 0 -11 6 -11
14 0 8 -8 17 -18 20 -26 9 -84 65 -77 75 3 6 -4 15 -15 21 -11 6 -18 15 -15
20 3 5 1 11 -5 15 -11 7 -14 25 -4 25 3 0 11 -6 18 -13z m321 -295 c0 -4 10
-17 22 -29 30 -30 56 -119 42 -144 -6 -12 -6 -19 0 -19 6 0 6 -12 -1 -32 -15
-44 -38 -85 -39 -73 -3 24 4 70 10 75 7 5 7 17 3 92 -1 15 -8 33 -15 40 -8 7
-13 22 -11 32 1 10 0 15 -3 12 -12 -12 -33 18 -33 47 0 25 3 29 13 19 6 -7 12
-16 12 -20z"/>
<path d="M850 910 c-12 -12 -20 -33 -20 -53 0 -70 76 -102 125 -52 30 30 32
64 4 99 -26 33 -79 36 -109 6z m56 -9 c-4 -5 3 -7 14 -4 14 4 21 -1 26 -21 9
-36 -1 -54 -31 -62 -18 -4 -32 -1 -45 11 -25 23 -25 33 2 62 21 22 45 32 34
14z"/>
<path d="M655 850 l0 -70 45 0 c61 0 90 21 90 66 0 50 -29 74 -87 74 l-48 0 0
-70z m89 44 c34 -33 10 -104 -35 -104 -17 0 -19 7 -19 60 0 53 2 60 19 60 11
0 27 -7 35 -16z"/>
<path d="M1031 839 c17 -33 32 -59 33 -57 2 2 17 29 33 61 28 52 29 57 12 57
-10 0 -18 -3 -17 -7 1 -5 -2 -21 -7 -37 -7 -27 -9 -28 -21 -11 -8 11 -14 27
-14 37 0 13 -8 18 -25 18 l-25 0 31 -61z"/>
<path d="M1154 840 c-28 -54 -30 -60 -13 -60 11 0 18 6 17 13 -2 7 7 12 22 12
15 0 24 -5 23 -12 -2 -8 7 -13 21 -13 l25 0 -27 60 c-15 32 -29 59 -32 60 -3
0 -19 -27 -36 -60z"/>
<path d="M1270 840 c0 -50 3 -60 18 -60 14 0 17 8 14 55 -3 45 -1 55 12 55 22
0 31 -27 16 -45 -10 -12 -9 -20 4 -40 13 -20 25 -25 59 -25 l42 0 0 60 c0 53
-2 60 -20 60 -18 0 -20 -5 -17 -55 3 -41 0 -55 -10 -55 -23 0 -40 37 -26 59
18 29 -5 51 -53 51 l-39 0 0 -60z"/>
<path d="M1474 888 c-4 -7 -14 -22 -21 -34 -12 -18 -11 -24 8 -47 11 -15 29
-27 40 -27 18 0 18 2 -6 27 -31 34 -31 40 -3 70 18 19 19 23 5 23 -9 0 -19 -6
-23 -12z"/>
<path d="M1537 893 c-15 -15 -5 -43 23 -63 17 -12 30 -26 30 -31 0 -16 -39
-10 -49 9 -9 15 -10 15 -11 -4 0 -27 22 -36 55 -24 36 14 33 51 -5 74 -32 18
-31 41 1 33 11 -3 19 -1 19 4 0 10 -53 12 -63 2z"/>
<path d="M1640 845 c7 -62 6 -60 42 -69 41 -10 68 22 68 79 0 29 -4 45 -12 45
-9 0 -13 -17 -13 -52 0 -50 -2 -53 -25 -53 -23 0 -25 3 -25 53 0 46 -2 52 -21
52 -18 0 -20 -4 -14 -55z"/>
<path d="M1775 840 l0 -60 42 0 c50 0 74 27 48 54 -9 9 -11 17 -5 21 7 4 7 13
0 26 -8 13 -21 19 -48 19 l-37 0 0 -60z m58 36 c3 -7 0 -19 -6 -25 -8 -8 -8
-11 2 -11 16 0 10 -42 -6 -48 -9 -2 -13 11 -13 47 0 48 12 67 23 37z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
